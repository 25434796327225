import React, { useEffect, useState } from 'react';
import { useLocation, navigate } from '@reach/router';
import axios from "axios";
import fetchJsonp from "fetch-jsonp";

const TrackingCodeHandler = () => {
  const location      = useLocation();
  const searchParams  = new URLSearchParams(location.search);
  const Tracking      = searchParams.get('tc');
  const accessToken   = `pat-na1-d2847da2-fb74-4cee-95a0-25734d2a935f`;
  const endpoint      = 'https://api.hubapi.com/crm/v3/objects/contacts';
  
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    fetch('https://leads.fulcrumgt.com/admin/do/api_get_job/')
      .then(function(response) {
        //console.log('response:', response);
        return response.json();
      })
      .then(function(json) {
        console.log('jsonData:', json);
        setJobs(json);
      })
      .catch(function(ex) {
        //console.log('parsing failed', ex);
      });
  }, []);

  //const jobs = ["Management", "Marketing", "Sales"];
  const [showOverlay, setShowOverlay]           = useState(!!Tracking);
  const [selectedJobTitle, setSelectedJobTitle] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    jobTitle: '',
  });
  const [contactId, setContactId] = useState('');

  useEffect(() => {
    if (Tracking) {
      setShowOverlay(true);
    }
  }, [Tracking]);

/*
  useEffect(() => {
    if (Tracking) {
      // Make an API call to your PHP backend to check if Tracking value exists in the database
      fetch('/admin/do/check_tc/?tc=' + Tracking)
        .then(response => response.json())
        .then(data => {
          console.log('API Response data:',data);
           console.log('API Response:',response);
          if (data) {
            setShowOverlay(true); // Show the pop-up if it exists in the database
          }else{
            setShowOverlay(false);
          }
        })
        .catch(error => {
          setShowOverlay(false);
        });
    }
  }, [Tracking]);*/

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('formData', JSON.stringify(formData));
    const storedData    = localStorage.getItem('formData');
    const parsedData    = JSON.parse(storedData);

    //const contactData = {
    // properties: {
    //    firstname: parsedData.firstName,
    //    lastname: parsedData.lastName,
    //    email: parsedData.email,
    //    company: parsedData.company,
    //    jobtitle: parsedData.jobTitle,
    //    referral_code: Tracking,
    //    lifecyclestage: `marketingqualifiedlead`,
    //    access_token: accessToken,
        // Add other custom properties here
    //  }
    //};

    //createOrUpdateContact(parsedData, contactData);
    //createContact(contactData, parsedData.jobTitle, accessToken);

    const contactData = {
        firstname: parsedData.firstName,
        lastname: parsedData.lastName,
        email: parsedData.email,
        company: parsedData.company,
        jobtitle: parsedData.jobTitle,
        referral_code: Tracking,
        lifecyclestage: `marketingqualifiedlead`,
        access_token: accessToken,
    };
    
    /*fetchJsonp(
      'https://leads.fulcrumgt.com/admin/hubspot.php?' +
        new URLSearchParams(contactData).toString()
    )
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        console.log('query was:', contactData);
        setData(json);
      })
      .catch(function(ex) {
        console.log('parsing failed', ex);
      });*/
      fetch('https://leads.fulcrumgt.com/admin/hubspot.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      })
        .then(response => response.json())
        .then(json => {
          //console.log('Hubspot Data sent successfully:', json);
          setShowOverlay(false);
          navigate(json);
        })
        .catch(ex => {
          console.log('Error sending data:', ex);
        });
  };

/*
  const searchContacts = async (query, accessToken) => {
    const endpoint = 'https://api.hubapi.com/crm/v3/objects/contacts/search';
    const headers = {
      'mode': 'no-cors',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };

    const searchCriteria = {
      filterGroups: [
        {
          filters: [
            {
              value: query, // Search query, e.g., an email address
              propertyName: 'email', // Property to search (replace with the property you're interested in)
              operator: 'EQ', // Use 'EQ' for an exact match
            },
          ],
        },
      ],
    };

    try {
      return axios.post(endpoint, searchCriteria, { headers });
    } catch (error) {
      throw error;
    }
  };


  const createOrUpdateContact = async (formData, contactData) => {
    const searchResponse = await searchContacts(formData.email, accessToken);
    const jobTitle = formData.jobTitle;

    if (searchResponse.status === 200) {
      const searchResults = searchResponse.data.results;
      if (searchResults.length > 0) {
        // Contact exists, get the ID and update
        const contactToUpdate = searchResults[0];
        updateContact(contactToUpdate.id, contactData, jobTitle, accessToken);
      } else {
        // Contact doesn't exist, create a new one
        createContact(contactData, jobTitle, accessToken);
      }
    } else {
      console.error('Error searching for contacts:', searchResponse.data);
    }
  };

  const createContact = async (contactData, jobTitle, accessToken) => {
    //const endpoint = 'https://api.hubapi.com/crm/v3/objects/contacts';
    const endpoint = 'https://leads.fulcrumgt.com/admin/hubspot.php';
    const headers = {
      //'mode': 'no-cors',
      //'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.post(endpoint, contactData, { headers });

      if (response.status === 201 || response.status === 200) {
        setShowOverlay(false);
        //if (jobTitle == 'Management') {
        //  navigate(`/solutions/managed-care`);
        //}else if (jobTitle == 'Marketing') {
        //  navigate(`/solutions/solutions-rapidx`);
        //}

        navigate(response.data);
        
        console.log('Contact created successfully:', response.data);
      } else {
        console.error('Error creating contact:', response.data);
      }
      console.log(response.data);
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  const updateContact = async (contactId, contactData, jobTitle, accessToken) => {
    const endpoint = `https://api.hubapi.com/crm/v3/objects/contacts/${contactId}`;
    const headers = {
      'mode': 'no-cors',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.patch(endpoint, contactData, { headers });

       if (response.status === 201 || response.status === 200) {
        setShowOverlay(false);
        //if (jobTitle == 'Management') {
        //  navigate(`/solutions/managed-care`);
        //}else if (jobTitle == 'Marketing') {
        //  navigate(`/solutions/solutions-rapidx`);
        //}
        navigate(response.data);
        
        console.log('Contact updated successfully:', response.data);
      } else {
        console.error('Error updating contact:', response.data);
      }
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

*/
  //console.log("Tracking", Tracking);

  return (
    <div>
     <style type="text/css">{`
        h2 {
          font-size: 30px;
          line-height: 1.75;
        }
        .overlay {
          display: ${showOverlay ? 'block' : 'none'};
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.7);
          z-index: 505;
        }
        .popup {
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 50px;
          max-width: 400px;
        }
        .close {
          float: right;
          cursor: pointer;
        }
        input {
          font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
          display: inline-block;
          width: 100%;
          height: 40px;
          padding: 9px 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #33475b;
          background-color: #f5f8fa;
          border: 1px solid #cbd6e2;
          box-sizing: border-box;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          border-radius: 3px;
        }
        select {
          font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
          display: inline-block;
          width: 100%;
          height: 40px;
          padding: 9px 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #33475b;
          background-color: #f5f8fa;
          border: 1px solid #cbd6e2;
          box-sizing: border-box;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          border-radius: 3px;
        }
        label {
          font-size: 13px;
          color: #212d3a;
          margin-bottom:4px;
        }
        .btn-submit {
          background: #ff7a59;
          border-color: #ff7a59;
          color: #ffffff;
          font-family: arial, helvetica, sans-serif;
          margin-top: 10px;
          padding: 10px 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;

        }
        select#jobTitle{
          font-size: 13px;
        }
      `}</style>
      {showOverlay && (
        <div id="popup" className="overlay">
          <div className="popup">
            <span className="close" onClick={closeOverlay}>&times;</span>
            <h2>Fill in the Form</h2>
            <form  onSubmit={handleSubmit} method="post">
              <label htmlFor="firstName">First Name:</label>
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                value={formData.firstName}
                onChange={handleInputChange}
                required /><br />

              <label htmlFor="lastName">Last Name:</label>
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                value={formData.lastName}
                onChange={handleInputChange}
                required /><br />

              <label htmlFor="email">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email}
                onChange={handleInputChange}
                required /><br />

              <label htmlFor="company">Company:</label>
              <input 
                type="text" 
                id="company" 
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                 /><br />

              <label htmlFor="jobTitle">Industry:</label>
                <select
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.JobTitle}
                  onChange={handleInputChange}
                >
                  {jobs.map(job => (
                    <option key={job} value={job}>
                      {job}
                    </option>
                  ))}
                </select><br/>
              <button type="submit" class="btn-submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackingCodeHandler;
